import React, { useState } from "react";
import SignatureComponent from "../form/signature";

const Testing = () => {
    const [signature, setSignature] = useState(null);
  return (
    <div>
      <SignatureComponent initialValue={signature} setSignature={setSignature} />
    </div>
  );
};

export default Testing;
