import React, { useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { Modal, Button } from "antd";
import { FaSignature } from "react-icons/fa6";

const SignatureComponent = ({ initialValue, setSignature }) => {
  const sigPadRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClear = () => {
    sigPadRef.current.clear();
  };

  const handleSave = () => {
    if (sigPadRef.current.isEmpty()) {
      alert("Please provide a signature");
      return;
    }
    // Save signature as base64 string
    const dataURL = sigPadRef.current.toDataURL();
    setSignature(dataURL);
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="signature-container" style={{ marginBottom: "20px" }}>
      {initialValue ? (
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span>Signature submitted</span>
            <Button type="primary" onClick={showModal}>
              Edit
            </Button>
          </div>
          <img
            src={initialValue}
            alt="Signature"
            style={{
              border: "1px solid #ccc",
              marginTop: "10px",
              width: "100%",
              maxWidth: "500px",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </div>
      ) : (
        <Button type="primary" style={{ width: "100%", maxWidth: "400px", backgroundColor: "gray", color: "black" }} onClick={showModal}>
          <FaSignature /> Add Your Signature
        </Button>
      )}

      <Modal
        title="Add Signature"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="clear" onClick={handleClear}>
            Clear
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
        width="100%"
        style={{ maxWidth: "550px" }}
      >
        <div
          style={{
            border: "1px solid #000",
            width: "100%",
            height: "200px",
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          <SignaturePad
            ref={sigPadRef}
            canvasProps={{
              className: "signature-canvas",
              width: window.innerWidth > 500 ? 500 : window.innerWidth - 40,
              height: 200,
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SignatureComponent;
