import { digitalFormConfig } from "./forms/lifeline/digital/config";
import { lifelineInPersonFormConfig } from "./forms/lifeline/inperson/config";
import { lifelineMbiFormConfig } from "./forms/lifeline/mbi/config";
import { snapArizonaConfig } from "./forms/snap/arizona/config";
import { wicMassConfig } from "./forms/wic/mass/config";

const formsConfig = { 
  lifeline: {
    imgSrc: "https://i0.wp.com/link-health.org/wp-content/uploads/2023/07/cropped-cropped-High-res-logo.webp?fit=150%2C150&ssl=1",
    title: "Lifeline Benefit",
    displayInHomePage:true,
    description:
      `This form will help our team ensure you get help signing up for the **Lifeline Benefit Program**. 
      
It only takes a few minutes to apply and start receiving the support you deserve.`,
    rows: digitalFormConfig,
  },
  wic: {
    displayInHomePage:true,
    imgSrc: "https://i0.wp.com/link-health.org/wp-content/uploads/2023/07/cropped-cropped-High-res-logo.webp?fit=150%2C150&ssl=1",
    title: "W.I.C Benefit",
    description: `

To access **WIC benefits**, including nutrition support and health resources for you and your family, please complete and submit the form.


It only takes a few minutes to apply and start receiving the support you deserve.

    `,
    rows: wicMassConfig,
  },
  snap: {
    imgSrc: "https://i0.wp.com/link-health.org/wp-content/uploads/2023/07/cropped-cropped-High-res-logo.webp?fit=150%2C150&ssl=1",
    title: "SNAP Benefit",
    description:`Register for the **SNAP** benefits. Fill this form which takes around 10 min.`,
    rows: snapArizonaConfig,
    displayInHomePage:true,
  },
};
export const formsConfigWithKey= Object.keys(formsConfig).map(key=>({...formsConfig[key], key}))
export default formsConfig;
