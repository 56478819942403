import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../../backend/users/get";
import Loading from "../../../components/ui/loading/Loading";
import UserTable from "./components/UserTable";
import {Terminology} from "./components/static";
import { routeMap } from "../../../routes";
import { Button, Modal, Typography, Divider, Row, Col, Card, Space, Tabs } from "antd";
import { useAtom } from "jotai";
import { rawUsersAtom, usersAtom } from "./users.jotai";
import RawUserTable from "./components/RawUserTable";

const { Title, Link, Text } = Typography;
const { TabPane } = Tabs;

const AdminDashboard = () => {
  const [users, setUsers] = useAtom(usersAtom);
  const [rawUsers, setRawUsers] = useAtom(rawUsersAtom);

  useEffect(() => {
    getAllUsers().then((users) => setUsers(users));
    getAllUsers("Raw").then((users) => setRawUsers(users));
  }, []);

  return (
    <main style={{ padding: "14px", backgroundColor: "#f0f2f5" }}>
      <Card style={{ borderRadius: "8px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
        <Title level={1} style={{ marginBottom: "16px", textAlign: "center" }}>
          Admin Dashboard
        </Title>

        {/* Links Section */}
        <section style={{ marginBottom: "24px" }}>
          <Row justify="center">
            <Space size="middle">
              {routeMap.map(
                (route, index) =>
                  route.path.includes("admin") &&
                  !route.path.includes("login") && (
                    <Link key={index} href={route.path} style={{ textTransform: "capitalize" }}>
                      {route.path.split("/")[2]}
                    </Link>
                  )
              )}
            </Space>
          </Row>
        </section>

        <Divider />

        {/* Submissions Section with Tabs */}
        <section>
          <Row align="middle" justify="space-between" style={{ marginBottom: "20px" }}>
            <Title level={2}>Submissions</Title>
            <Button
              type="primary"
              onClick={() => {
                Modal.info({
                  title: "Terminology",
                  content: <Terminology />,
                  width: 600,
                  centered: true,
                });
              }}
            >
              Terminology
            </Button>
          </Row>

          <Tabs defaultActiveKey="processed" type="card">
            <TabPane tab="Partial Submissions" key="raw">
              {rawUsers ? <RawUserTable users={rawUsers} realUsers={users} /> : <Loading />}
            </TabPane>
            <TabPane tab="Completed Submissions" key="processed">
              {users ? <UserTable users={users} rawUsers={rawUsers} setUsers={setUsers} /> : <Loading />}
            </TabPane>
          </Tabs>
        </section>
      </Card>
    </main>
  );
};

export default AdminDashboard;
