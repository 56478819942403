import React, { useEffect, useState } from "react";
import moment from "moment";
import useSize from "../../components/use/useSize";
import ThankYouMessage from "./components/ui/ThankyouForSubmitting";
import FormComponent from "./form";
import { cleanObject } from "../../helpers/obj";
import { useParams } from "react-router-dom";
import formsConfig from "./config";
import ReactMarkdown from "react-markdown";
import { getLsId } from "../../helpers/random";
import { createUser, updateUser } from "../../backend/users/set";
import { getUserById } from "../../backend/users/get";
import { catcher } from "../../components/trycatch/catcher";
import Loading from "../../components/ui/loading/Loading";
import { RoughNotation } from "react-rough-notation";
import getRandomImage from "../../assets/js/coverimages";
import * as chatbot from "../../Chatbot";
import { Card, Divider, Drawer, message, Modal } from "antd";
import { FaCircle, FaUser } from "react-icons/fa6";

import { FaInfoCircle, FaRedoAlt, FaUserCircle } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import Profile from "../Profile/profile";
import axios from "axios";
import { clearLocalStorageExcept } from "../../helpers/loc";

const UserMenu = ({ user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isMobile } = useSize(); // Determines screen size

  if (!user) return null;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const restartSession = () => {
    clearLocalStorageExcept();
    window.location.reload();
  };

  const menuItems = [
    {
      title: "Your Profile",
      icon: <FaUser size={40} />,
      action: () =>
        Modal.info({
          width: "100%",
          title: "Your Information",
          content: (
            <>
              <Profile user={user} />
            </>
          ),
        }),
    },
    {
      title: "Restart Session",
      icon: <FaRedoAlt size={40} />,
      action: restartSession,
    },
    {
      title: "About link-health.org",
      icon: <FiExternalLink size={40} />,
      action: () => window.open("https://link-health.org", "_blank"),
    },
  ];
  if (!user.first_name) {
    user.first_name = "User";
  }

  return (
    <div>
      <div
        translate="no"
        onClick={toggleMenu}
        style={{
          cursor: "pointer",
          fontSize: "1.5rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FaUserCircle style={{ marginRight: "10px" }} /> {user?.first_name}
      </div>

      <Drawer
        title="Menu"
        placement="right"
        style={{ marginTop: "20px" }}
        onClose={toggleMenu}
        open={isMenuOpen}
        width={isMobile ? "100%" : "80%"}
        bodyStyle={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {user.first_name && (
          <div style={{ fontSize: "2.5rem", marginBottom: "20px" }}>
            Hello, {user.first_name}!
          </div>
        )}

        {menuItems.map((item, index) => (
          <Card
            key={index}
            hoverable
            onClick={item.action}
            style={{
              width: "100%",
              padding: "20px",
              fontSize: "1.5rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "20px" }}>{item.icon}</div>
            <div translate="no">{item.title}</div>
          </Card>
        ))}
      </Drawer>
    </div>
  );
};

const { addBotMessage, fetchAIResponse } = chatbot;
const fallbackConfig = {
  imgSrc: "",
  title: "{placeholder}",
  headerBgColor: "white",
  description: "{placeholder description}.",
  headerTextColor: "black",
  boxShadow: "rgba(0, 0, 0, 0.14) 0px 3px 8px",
  thankYouMessage: "Thank you for submitting the form.",
  rows: [],
};

const FormIndex = ({ displayOnly = false, options }) => {
  // if display Only it must be an object
  let { id: form_name } = useParams();
  const { isMobile } = useSize();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [uuid, setUuid] = useState(undefined);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (displayOnly) {
      form_name = options.form_name;
      setUser(options.user);
      setUuid(options.user?.id);
      console.log("display only", options.user)
    } else if (!uuid) {
      catcher(
        async () => {
          let uuid = getLsId();
          let user = await getUserById(uuid);
          if (!user) {
            uuid = await createUser({}, uuid);
            user = await getUserById(uuid);
          }

          setUser(user);
          if (user?.[`${form_name}_submitted`]) setIsSubmitted(true);
          setUuid(uuid);
        },
        { setLoading: () => {} }
      );
    }
  }, []);

  if (displayOnly) {
    form_name = options.form_name;
  }
  console.log(user, uuid);

  const formConfig = formsConfig[form_name?.toLowerCase()];

  if (!formConfig) return "Form not found: " + form_name;

  const config = {
    ...fallbackConfig,
    ...formConfig,
  };

  const configRows = config.rows;

  const handleSubmit = async (formData) => {
    catcher(async () => {
      const data = { ...formData };

      console.log("data", data);

      // if (!Array.isArray(data.consent) || !data.consent[0]) {
      //   throw new Error("Consent is required.");
      // }

      const userData = cleanObject({
        ...user,
        ...data,
        loc: "ma",
        input_by_user: true,
        dob: moment(data.dob, "MM/DD/YYYY").format("DD-MM-YYYY"),
        date_of_birth: moment(data.dob, "MM/DD/YYYY").format("DD-MM-YYYY"),
        createdOn: Date.now(),
        name: `${data?.first_name} ${data?.last_name}`,
        [`${form_name}_submitted`]: true,
      });

      await updateUser(uuid, userData);

      axios.post(`${process.env.REACT_APP_CHAT_BACKEND_BASE_URL}on_submit`, {
        data: {
          name: userData.name,
          email: userData.email,
          phone: userData.phone_number,
          type: form_name,
          link: window.location.origin + "/admin/dashboard",
        },
      });

      setIsSubmitted(true);
      setUser({ ...userData });
      // suppossing uuid is 100% there
      console.log("Form data submitted successfully:", userData);
      message.info("Form submitted. Starting the chat again.");
      const resp = await fetchAIResponse(
        "I have submitted " +
          form_name +
          " Thank you for completing this! Now, please ask me to complete the next application in the queue. Give me the link for it as well. For your reference, this is my complete information so far." +
          JSON.stringify(formData)
      );
      addBotMessage(resp, true);
    }, {});
  };

  const header = (
    <header
      style={{
        height: "80px",
        marginTop: "20px",
        background: config.headerBgColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 20px",
        color: config.headerTextColor,
        position: "fixed",
        top: 0,
        width: isMobile ? "90%" : "68%",
        zIndex: 10,
      }}
    >
      {!isMobile && false && config.imgSrc && (
        <img
          src={config.imgSrc}
          style={{ maxHeight: "100%" }}
          alt={config.title}
        />
      )}
      {isMobile ? (
        <h2 className="notranslate underline" style={{ marginLeft: "10px" }}>
          {/* <RoughNotation
            color="royalblue"
            type="underline"
            strokeWidth={2}
            show
          > */}
          {config.title}
          {/* </RoughNotation> */}
        </h2>
      ) : (
        <h1>Register</h1>
      )}
      {user ? (
        <div style={{ marginLeft: "auto" }}>
          <UserMenu user={user} />{" "}
        </div>
      ) : (
        <div style={{ marginLeft: "auto" }}>
          <FaCircle />
        </div>
      )}
    </header>
  );

  const formContent =
    user !== undefined ? (
      <FormComponent
        displayOnly={displayOnly}
        uuid={uuid}
        id={form_name}
        initialValues={{ ...user }}
        formData={configRows}
        onSubmit={handleSubmit}
      />
    ) : (
      <Loading />
    );

  const formDescription = (
    <p
      style={{
        fontSize: "larger",
        marginBottom: "30px",
        borderTop: isMobile ? "0px solid black" : "1px solid grey",
        borderBottom: "1px solid grey",
      }}
    >
      <ReactMarkdown>{config.description}</ReactMarkdown>
    </p>
  );
  if (displayOnly)
    return (
      <div>
        <Divider style={{ textTransform: "capitalize" }}>
          NAME OF FORM = {form_name}
        </Divider>
        {formContent}
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        background: "white",
      }}
    >
      {/* Left half with image - Fixed on scroll and hidden on mobile */}
      {!isMobile && (
        <div
          style={{
            width: "30%",
            height: "100%",
            position: "fixed",
            filter: "blur(0px)",
            left: 0,
            top: 0,
          }}
        >
          <img
            className="curve"
            src={
              "https://st4.depositphotos.com/3930503/19991/i/450/depositphotos_199910082-stock-photo-light-blue-gradient-background-blue.jpg"
            }
            alt="Form Image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "2px", // Optional padding
              borderRadius: "8px", // Optional for rounded corners
            }}
          >
            <h2
              className="notranslate underline"
              style={{ marginLeft: "10px" }}
            >
              <RoughNotation
                color="royalblue"
                type="underline"
                strokeWidth={2}
                show
              >
                {config.title}
              </RoughNotation>
            </h2>

            {formDescription}
          </div>
        </div>
      )}

      {/* Right half with form - Scrollable and full height */}
      <div
        style={{
          width: isMobile ? "100%" : "70%",
          marginLeft: isMobile ? 0 : "30%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Fixed header */}

        {header}

        {/* Scrollable form content */}
        <main
          style={{
            flex: 1,
            marginTop: isMobile ? "80px" : "80px", // Offset for the fixed header
            padding: "40px",
            overflowY: "auto",
            background: "white",
          }}
        >
          {isSubmitted ? (
            <ThankYouMessage
              message={config.thankYouMessage}
              handleMainLinkClick={async () => {
                message.info("loading chat...");
                fetchAIResponse(
                  "I have submitted " +
                    form_name +
                    " , say thankyou to me for it. and then urge me to complete the next one."
                ).then((resp) => {
                  addBotMessage(resp, true);
                });
              }}
            />
          ) : (
            <>
              <>{isMobile && formDescription}</>
              <>{formContent}</>
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default FormIndex;
