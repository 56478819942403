import React, { useState } from "react";
import { Table, Typography, Input, Space, Alert, Modal, Button } from "antd";
import moment from "moment";
import formsConfig, { formsConfigWithKey } from "../../../form/config";
import Loading from "../../../../components/ui/loading/Loading";
import { tableIcons } from "./static";
import FormIndex from "../../../form";
import { TextAreaWithSaveCancel } from "antd-elements";
import { updateUser } from "../../../../backend/users/set";
import { getUserById } from "../../../../backend/users/get";

const { Text } = Typography;
const { Search } = Input;

export const partialToRealUserId = (id) => {
  const realId = id.split("-")[1];
  return realId;
};

const getUserFilledForms = (user) => {
  return formsConfigWithKey.filter((form) => user[form.key + "_submitted"]).map((form) => form.key);
};

const getUserPartiallyFilledForms = (user) => {
  return formsConfigWithKey.filter((form) => user[form.key + "-last_saved_on"]).map((form) => form.key);
};

const RawUserTable = ({ users, realUsers }) => {
  const [searchText, setSearchText] = useState("");

  if (!users) return <Loading />;

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  let filteredUsers = users.filter((user) => (user.name && user.name.toLowerCase().includes(searchText)) || (user.first_name && user.first_name.toLowerCase().includes(searchText)));

  const columns = [
    {
      title: "Date",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (createdOn, row) => {
        const form = getUserPartiallyFilledForms(row)[0];
        return <Text>{moment(row[form + "-last_saved_on"]).format("DD MMM")}</Text>;
      },
      sorter: (a, b) => {
        const formA = getUserPartiallyFilledForms(a)[0];
        const formB = getUserPartiallyFilledForms(b)[0];
        return new Date(a[formA + "-last_saved_on"]) - new Date(b[formB + "-last_saved_on"]);
      },
      defaultSortOrder: 'descend',
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "Name",
      width: "200px",
      key: "name",
      render: (user) => {
        const name = user.name || user.first_name || "";
        return (
          <Text strong ellipsis>
            {name}
          </Text>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "Zip Code",
      dataIndex: "zip",
      key: "zip",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "View Partially Filled Application",
      key: "view_partially_filled_application",
      render: (row) => {
        const form = getUserPartiallyFilledForms(row)[0];
        return (
          <Button
            type="link"
            onClick={() =>
              Modal.info({
                width: "80vw",
                destroyOnClose: true,
                title: "Partially Filled Application",
                content: <FormIndex displayOnly={true} options={{ user: row, form_name: form }} />,
              })
            }
          >
            View
          </Button>
        );
      },
    },
    {
      title: "Notes",
      key: "notes",
      render: (row) => {
        return (
          <TextAreaWithSaveCancel
            initialValue={realUsers.find((user) => user.id === partialToRealUserId(row.id))?.notes}
            onUpdate={async (notes) => {
              await updateUser(partialToRealUserId(row.id), { notes: notes });
            }}
          />
        );
      },
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      {/* <Alert showIcon style={{ padding: "10px", marginBottom: "20px" }} message={`Total unique user sessions=${users.length}`} /> */}
      <Space style={{ marginBottom: 16 }}>
        <Search style={{ width: "30vw" }} placeholder="Search by Name" allowClear enterButton="Search" size="large" onSearch={handleSearch} />
      </Space>
      <Table columns={columns} dataSource={filteredUsers} pagination={false} rowKey="id" bordered size="middle" style={{ borderRadius: "10px" }} />
    </div>
  );
};

export default RawUserTable;
